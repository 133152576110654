import React from "react";
import SpotlightContainer from "../spotlight";
import Flutter from "../../../images/flutter.png";
import Kotlin from "../../../images/kotlin.png";
import Swift from "../../../images/swift.png";
import Image from "../../../images/sd1.png";

function Project() {
  const jobData = {
    jobName: "Aella Mobile App",
    button: "View",
    link: "https://play.google.com/store/apps/details?id=com.aella.comportal&hl=en&gl=NG",
    image: Image,
    stack: [
      {
        name: "Flutter",
        image: Flutter,
      },
      {
        name: "Kotlin",
        image: Kotlin,
      },
      {
        name: "Swift",
        image: Swift,
      },
    ],

    description:
      "I led the end-to-end refactoring of a mobile loan application using Flutter, following Agile methodologies. The rebuilt app has successfully processed over $100 million in loans, significantly enhancing user experience and operational efficiency. This project involved a complete overhaul of the app’s architecture, UI/UX design, and functionality to meet the growing demands of users and stakeholders.",
    roles: [],
  };

  return <SpotlightContainer jobData={jobData} />;
}
export default Project;
